<template>
    <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Описание</th>
              <th>Код</th>
              <th v-for="item in balance.arr">{{item.date}}</th>
            </tr>
          </thead>
          <tbody>
          <tr><th class='text-center titles' colspan="7"><h4>Отчет о финансовых результатах</h4></th></tr>
            <tr v-for="item in balance.codes" v-if="item && item[2][0] === '2'">
              <th>{{item[1]}}</th>
              <th>{{item[2]}}</th>
              <th><input type="text" class="form-control" v-model="balance.arr[0][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[1][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[2][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[3][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[4][item[0]]"></th>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "OfrTable",
        props: ['balance'],
        methods: {
        },
    }
</script>

<style scoped>

    .titles {
        background-color: #ddd9c2;
    }

</style>