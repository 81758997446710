<template>
    <div class="container">
        <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2 text-right">
                <span class="btn btn-primary" @click="blocks.push({aspects: []})" style='margin-right: 15px'>
                    <font-icons icon="plus"></font-icons>
                </span>
                <br>
                <br>
            </div>
        </div>
        <table style="border: solid 1px" class="table table-striped" v-for="(group, index) in blocks">
          <thead class="thead-dark">
            <tr>
              <th>
                  <input type="text" placeholder="Название подгруппы" v-model="group.name" class="form-control">
              </th>
              <th class="text-center">
                    <span class="btn btn-danger" @click="blocks.splice(index,1)">
                            <font-icons icon="trash"></font-icons>
                    </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='(item, index2) in group.aspects'>
              <th><input type="text" placeholder="Наименование" v-model="item.name" class="form-control"> </th>
              <th class="text-center" style="width: 150px">
                  <div class="row">
                      <div class="col-md-6 text-center">
                          <span class="btn btn-info" @click="openModal('aspectmap_modal', item)">
                            <font-icons icon="cogs"></font-icons>
                          </span>
                      </div>
                      <div class="col-md-6 text-center">
                          <span class="btn btn-danger" @click="group.aspects.splice(index2, 1)">
                            <font-icons icon="trash"></font-icons>
                          </span>
                      </div>
                  </div>
              </th>
            </tr>
          <tr>
              <th colspan="6" class="text-center">
                  <button class="btn btn-primary" @click="group.aspects.push({items: []})">Добавить</button>
              </th>
          </tr>
          </tbody>
        </table>
        <div class="row">
            <div class="col-md-12 text-center">
                <button class="btn btn-success" @click="save">
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AspectGenerator",
        data () {
            return {
                blocks: []
            }
        },
        created () {
            this.getGroups()
        },
        methods: {
            getGroups () {
                LoadStart()
                axios.get('/api/structure/aspects').then(response => {
                    this.blocks = response.data
                }).finally(() => {
                    LoadStop()
                })
            },
            openModal (component, item) {
                Modal.open({component, data: item, width: '1200px'}).then(data => {
                    Modal.close()
                })
            },
            save () {
                LoadStart()
                axios.post('/api/structure/aspects', {blocks: this.blocks}).then(response => {
                    swal.fire('Сохранено', '', 'success')
                    this.$router.push('/')
                }).catch(() => {
                    swal.fire('Технические ошибки', '', 'error')
                }).finally(()=> {
                    LoadStop()
                })
            }
        }
    }
</script>

<style scoped>

    .icon {
        margin-top: 11px
    }

</style>