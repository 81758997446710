<template>
    <div class="container-fluid">
        <table class="table table-striped" v-for="block in structure.indicator_blocks">
          <thead class="thead-dark">
            <tr>
              <th colspan="4">{{block.name}} <input type="number" placeholder="Вес подгруппы" v-model='block.weight' class="form-control"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='item in block.indicators'>
              <th><input type="checkbox" v-model="item.active"></th>
              <th scope="row">{{item.name}} </th>
              <th v-if='item.active'><input type="number" class="form-control" placeholder="Вес" v-model="item.weight"></th>
              <th v-else></th>
              <th v-if='item.active' class="btn badge badge-info icon" @click="openModal(item)">
                  <font-icons icon="cogs"></font-icons>
              </th>
              <th v-else></th>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>

    export default {
        name: "indicator",
        props: ['structure', 'sources'],
        data () {
          return {
              opened: false,
              component: '',
              title: '',
              item: {}
          }
        },
        mounted () {
            this.getActivities()
        },
        methods: {
            openModal (item) {
                item.sources = this.sources
                item.activities = this.getActivities()
                Modal.open({component: 'req_modal', data: item, width: '1200px'}).then(() => {
                    Modal.close()
                })
            },
            getActivities () {
                let activities = []
                this.structure.activities.map(item => {
                    let flag = false
                    item.items.map(elem => {
                        if (elem.active) {
                            flag = true
                        }
                    })
                    if (flag) {
                        activities.push(item)
                    }
                })
                return activities
            }
        },
    }
</script>

<style scoped>

    .icon {
        margin-top: 11px
    }

</style>