export default new class{
	constructor(){
		this.ModalVue;
	}
	open(options){
		return new Promise((res, rej) => {
			options.prom = {res, rej};
			this.ModalVue.open(options);
		})
	}
	close(){
		this.ModalVue.close();
	}
	closePath(){
		return false
	}
    openMpage(options){
     this.ModalMpage.open(options);
    }
    closeAllMpage({fast = false} = {}){
     this.ModalMpage.closeAll({fast});
    }
    closeLastMpage(){
     this.ModalMpage.closeLast();
    }
}