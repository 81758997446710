<template>
  <div id="app">
      <div v-if="profile">
          <loader></loader>
          <modal ref="modal"></modal>
          <m-page></m-page>
          <my-header></my-header>
          <br>
          <router-view></router-view>
          <my-footer></my-footer>
      </div>
      <login v-else></login>
  </div>
</template>

<script>
import Home from './views/Home'
import Footer from '@/views/Footer'
import Header from '@/views/Header'
import axios from 'axios'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

import Modal from '@/components/modals/Modal.vue'
import Mpage from '@/components/modals/Mpage.vue'
import Login from '@/components/Login.vue'
import Loader from '@/views/Loader.vue'


export default {
  name: 'app',
  components: {
      'home': Home,
      'my-footer': Footer,
      'my-header': Header,
      'modal': Modal,
      'm-page': Mpage,
      'login': Login,
      'loader': Loader
  },
  data () {
      return {
          profile: false
      }
  },
  created() {
      axios.get('/api/user/profile').then(response => {
          if(Object.keys(response.data).length != 0) {
              this.profile = response.data
          }
      })
  }

}
</script>

<style lang="scss">
#app {}
</style>
