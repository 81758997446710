<template>
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <h3>{{data.name}}</h3>
                <hr>
            </div>
            <div class="col-md-2">
                <button class="btn-lg btn-danger" @click="close">Закрыть</button>
            </div>
            <div class="col-md-12 text-center">
                <div class="row">
                    <div class="col-md-6 border">
                        <b>Описание:</b>
                        <p>{{data.description}}</p>
                    </div>
                    <div class="col-md-6 border">
                        <b>Формула:</b>
                        <p>{{data.formula}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row text-center">
                    <div class="col-md-6 border">
                        <b>Источник:</b>
                        <select v-model="data.source" class="form-control" disabled>
                            <option :value='item.id' v-for="item in data.sources">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 border">
                        <b>Описание формулы:</b>
                        <p>{{data.formula_description}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center border">
                <b>Расчет показателя</b>
                <input type="text" class="form-control" v-model="data.count_formula" disabled>
            </div>
            <div class="col-md-12 text-center">
                <b>Нормативные значение</b>
                <div class="row">
                    <div class="col-md-5 border">Интервалы</div>
                    <div class="col-md-3 border">Балл</div>
                    <div class="col-md-3 border">Вид деятельности</div>
                </div>
                <div class="row" v-for="(item, index) in data.values">
                    <div class="col-md-5 border">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="number" class="form-control" placeholder="Начало" v-model="item.min" disabled>
                            </div>
                            <div class="col-md-6">
                                <input type="number" class="form-control" placeholder="Конец" v-model="item.max" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 border" >
                        <input type="number" class="form-control" v-model="item.score" placeholder="Балл" disabled>
                    </div>
                    <div class="col-md-3 border" >
                        <input type="text" class="form-control" v-model="item.activity" placeholder="Балл" disabled>
                    </div>
                    <div class="col-md-1">
                        <span class="btn badge badge-danger" :class='{btn_opacity: !item.downfactors.bad}'>
                            Плохой
                        </span>
                        <span class="btn badge badge-warning" :class='{btn_opacity: !item.downfactors.normal}'>
                            Средний
                        </span>
                        <span class="btn badge badge-success" :class='{btn_opacity: !item.downfactors.good}' >
                            Хороший
                        </span>
                        <br>
                        <br>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "IndicatorShowModal",
        props: ['data'],
        methods: {
            close () {
                Modal.close()
            },
        }
    }
</script>

<style scoped>

    div {

    }
    .border {
        border: solid 1px;
        padding: 12px
    }

    .btn_opacity {
        opacity: 0.1;
    }

</style>