<template>
    <div>Понижающие факторы</div>
</template>

<script>
    export default {
        name: "FactorGenerator",
    }
</script>

<style scoped>

</style>