<template>
    <div>
        <table class="table">
              <thead>
                <tr class="text-center" @click='active = !active'>
                  <th v-if='active' class="tabs" :class="{active: active}" >Документы <font-icons icon="arrow-circle-up" id="up"></font-icons></th>
                  <th v-else class="tabs" :class="{active: !active}">Документы <font-icons id='down' icon="arrow-circle-down"></font-icons></th>
                </tr>
              </thead>
        </table>
        <div v-if="active">
            Тут список документов
        </div>
    </div>
</template>

<script>
    export default {
        name: "Documents",
        data () {
            return {
                active: false,
                tab: false
            }
        }
    }
</script>

<style scoped>

    .tabs:hover {
        cursor: pointer;
        border: solid 1px;
    }

    #up {
        color: red;
    }

    #down {
        color: green;
    }

</style>