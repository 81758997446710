<template>
    <div>
        <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-2 login">
                <div class="row text-center">
                    <div class="col-md-12"><h4>Авторизация</h4></div>
                    <div class="col-md-12">
                        <input type="text" class="form-control margindiv" placeholder="Логин" v-model="login">
                        <input type="password" class="form-control margindiv" placeholder="Пароль" v-model="password">
                        <button class="btn btn-success margindiv" @click="checkUser">Отправить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data () {
            return {
                login: '',
                password: ''
            }
        },
        methods: {
            checkUser() {
                LoadStart()
                axios.post('/api/user/login', {login: this.login, password: this.password}).then(response => {
                    LoadStop()
                     location.reload()
                }).catch(response => {
                    LoadStop()
                    swal.fire('Неправильный логин или пароль', '', 'error')
                })
            }
        }
    }
</script>

<style scoped>
    .margindiv {
        margin-top: 15px
    }

    .login {
        margin-top: 100px
    }


</style>