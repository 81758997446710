<template>
    <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th align="right" @click="countSum">
                <button class="btn badge-pill badge-primary">Расчет сумм</button>
              </th>
            </tr>
            <tr>
              <th>Описание</th>
              <th>Код</th>
              <th v-for="item in balance.arr">{{item.date}}</th>
            </tr>
          </thead>
          <tbody>
          <tr><th class='text-center elements' colspan="7"><h4>Актив</h4></th></tr>
          <tr><th class='text-center titles' colspan="7"><h5>Внеоборотные активы</h5></th></tr>
            <tr v-for="item in balance.codes" v-if="item && item[2].slice(0,2) == '11'">
              <th>{{item[1]}}</th>
              <th>{{item[2]}}</th>
              <th><input type="text" class="form-control" v-model="balance.arr[0][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[1][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[2][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[3][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[4][item[0]]"></th>
            </tr>
          <tr><th class='text-center titles' colspan="7"><h5>Оборотные активы</h5></th></tr>
            <tr v-for="item in balance.codes" v-if="item && (item[2].slice(0,2) == '12' || item[2] == '1600')">
              <th>{{item[1]}}</th>
              <th>{{item[2]}}</th>
              <th :class="{dangerColor: item[2] === '1600' && balance.arr[0]['code1600'] !== balance.arr[0]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[0][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1600' && balance.arr[1]['code1600'] !== balance.arr[1]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[1][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1600' && balance.arr[2]['code1600'] !== balance.arr[2]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[2][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1600' && balance.arr[3]['code1600'] !== balance.arr[3]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[3][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1600' && balance.arr[4]['code1600'] !== balance.arr[4]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[4][item[0]]"></th>
            </tr>
          <tr><th class='text-center elements' colspan="7"><h5>Пассив</h5></th></tr>
          <tr><th class='text-center titles' colspan="7"><h4>Капитал и резервы</h4></th></tr>
            <tr v-for="item in balance.codes" v-if="item && item[2].slice(0,2) == '13'">
              <th>{{item[1]}}</th>
              <th>{{item[2]}}</th>
              <th><input type="text" class="form-control" v-model="balance.arr[0][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[1][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[2][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[3][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[4][item[0]]"></th>
            </tr>
          <tr><th class='text-center titles' colspan="7"><h5>Долгосрочные обязательства</h5></th></tr>
            <tr v-for="item in balance.codes" v-if="item && item[2].slice(0,2) == '14'">
              <th>{{item[1]}}</th>
              <th>{{item[2]}}</th>
              <th><input type="text" class="form-control" v-model="balance.arr[0][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[1][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[2][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[3][item[0]]"></th>
              <th><input type="text" class="form-control" v-model="balance.arr[4][item[0]]"></th>
            </tr>
          <tr><th class='text-center titles' colspan="7"><h5>Краткосрочные обязательства</h5></th></tr>
            <tr v-for="item in balance.codes" v-if="item && (item[2].slice(0,2) == '15' || item[2] =='1700')">
              <th>{{item[1]}}</th>
              <th>{{item[2]}}</th>
              <th :class="{dangerColor: item[2] === '1700' && balance.arr[0]['code1600'] !== balance.arr[0]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[0][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1700' && balance.arr[1]['code1600'] !== balance.arr[1]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[1][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1700' && balance.arr[2]['code1600'] !== balance.arr[2]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[2][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1700' && balance.arr[3]['code1600'] !== balance.arr[3]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[3][item[0]]"></th>
              <th :class="{dangerColor: item[2] === '1700' && balance.arr[4]['code1600'] !== balance.arr[4]['code1700']}"><input type="text" class="form-control" v-model="balance.arr[4][item[0]]"></th>
            </tr>
          </tbody>
        </table>



    </div>
</template>

<script>
    export default {
        name: "BalanceTable",
        props: ['balance'],
        created () {
          if (this.balance.codes[this.balance.codes.length - 1].length < 3) {
            this.filterCodes()
          }
        },
        methods: {
            filterCodes() {
                for (let i = 0; i < this.balance.codes.length; i++) {
                    if (this.balance.codes[i][0].slice(0,4) !== 'code') {
                        delete this.balance.codes[i]
                    } else {
                        this.balance.codes[i].push(this.balance.codes[i][0].slice(4,8))
                    }
                }
                this.balance.codes.filter(elem => {
                    return elem
                })
                this.$forceUpdate()
            },
          countSum () {
              // Обнуляем все суммы
              for (let i = 0; i < 5; i++) {
                this.balance.arr[i]['code1100'] = 0
                this.balance.arr[i]['code1200'] = 0
                this.balance.arr[i]['code1600'] = 0
                this.balance.arr[i]['code1300'] = 0
                this.balance.arr[i]['code1400'] = 0
                this.balance.arr[i]['code1500'] = 0
                this.balance.arr[i]['code1700'] = 0
              }
              // Сначала считаем суммы разделов
              this.balance.codes.map(item => {
                if (item && item[2].slice(0,2) == '11' && item[2] != '1100') {
                  for (let i=0; i<5; i++) {
                    if (this.balance.arr[i][item[0]]) {
                      this.balance.arr[i]['code1100'] += parseFloat(this.balance.arr[i][item[0]])
                    }
                  }
                } else if (item && item[2].slice(0,2) == '12' && item[2] != '1200' && item[2] != '1231' && item[2] != '1232') {
                  for (let i=0; i<5; i++) {
                    if (this.balance.arr[i][item[0]]) {
                      this.balance.arr[i]['code1200'] += parseFloat(this.balance.arr[i][item[0]])
                    }
                  }
                } else if (item && item[2].slice(0,2) == '13' && item[2] != '1300') {
                  for (let i=0; i<5; i++) {
                    if (this.balance.arr[i][item[0]]) {
                      this.balance.arr[i]['code1300'] += parseFloat(this.balance.arr[i][item[0]])
                    }
                  }
                } else if (item && item[2].slice(0,2) == '14'&& item[2] != '1400') {
                  for (let i=0; i<5; i++) {
                    if (this.balance.arr[i][item[0]]) {
                      this.balance.arr[i]['code1400'] += parseFloat(this.balance.arr[i][item[0]])
                    }
                  }
                } else if (item && item[2].slice(0,2) == '15'&& item[2] != '1500') {
                  for (let i=0; i<5; i++) {
                    if (this.balance.arr[i][item[0]]) {
                      this.balance.arr[i]['code1500'] += parseFloat(this.balance.arr[i][item[0]])
                    }
                  }
                }
                // Расчет сумм балансов
                for (let i = 0; i < 5; i++) {
                  this.balance.arr[i]['code1600'] = parseFloat(this.balance.arr[i]['code1100']) + parseFloat(this.balance.arr[i]['code1200'])
                  this.balance.arr[i]['code1700'] = parseFloat(this.balance.arr[i]['code1300']) + parseFloat(this.balance.arr[i]['code1400']) + parseFloat(this.balance.arr[i]['code1500'])
                }
              })
            this.$forceUpdate()
          }

        },
    }
</script>

<style scoped>

    .titles {
        background-color: #ddd9c2;
    }

    .elements {
      background-color: #7dff8d;
    }

    .dangerColor input {
        background-color: #ff9090;
    }

</style>