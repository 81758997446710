<template>
    <div class="container-fluid">
        <div class="col-md-12 text-center">
            <hr>
            <h4>Источники</h4>
            <hr>
        </div>
        <div class="row">
            <div class="col-md-4 margindiv border text-center" v-for="item in structure.sources">
                {{item.name}}
            </div>
        </div>


    </div>
</template>
<script>

    export default {
        name: "Source",
        props: ['structure'],
        data () {
          return {

          }
        },
        methods: {
        },
    }
</script>

<style scoped>

    .margindiv {
        margin-top: 3px
    }
    .border {
        border: solid 1px;
        padding: 12px
    }

</style>