<template>
  <div class="home">
    <div class="container">
      <div id="menu">
        <div class="widget">
          <ul>
            <router-link class="nav-link" tag='li' to="/generator">Генератор</router-link>
            <router-link class="nav-link" tag='li' to="/reg">Создание методики</router-link>
            <router-link class="nav-link" tag='li' to="/show">Просмотр методики</router-link>
            <router-link class="nav-link" tag='li' to="/count">Расчет рейтинга</router-link>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'home',

}
</script>

<style>

  #menu {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%)
  }

* {box-sizing: border-box; margin: 0;}
.widget {
  padding: 20px;
  border: 5px solid #f1f1f1;
  background: #fff;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
}
.widget h3 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  color:  #424949;
}
.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 400px;
}
.widget li {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.widget li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.widget a {
  text-decoration: none;
  color:  #616a6b;
  display: inline-block;
}
.widget li:before {
  font-family: FontAwesome;
  font-size: 20px;
  vertical-align:bottom;
  color: #dd3333;
  margin-right: 14px;
}


</style>
