<template>
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <input type="text" class="form-control" v-model="data.name">
                <hr>
            </div>
            <div class="col-md-2">
                <button class="btn-lg btn-danger" @click="data.prom.res(data)">Закрыть</button>
            </div>
            <div class="col-md-12 text-center">
                <div class="row">
                    <div class="col-md-6 border">
                        <b>Описание:</b>
                        <textarea v-model="data.description" class="form-control"></textarea>
                    </div>
                    <div class="col-md-6 border">
                        <b>Формула:</b>
                        <input type="text" class="form-control" v-model="data.formula">
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row text-center">
                    <div class="col-md-6 border">
                        <b>Источники:</b>
                        <select class="form-control" v-model="data.source">
                            <option class="form-control" :value='source.id' v-for="source in data.source_list">{{source.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 border">
                        <b>Описание формулы:</b>
                        <textarea class="form-control" v-model="data.formula_description"></textarea>
                    </div>
                    <div class="col-md-12 border text-center">
                        <b>Расчет показателя</b>
                        <input type="text" class="form-control" v-model="data.count_formula">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "IndicatorMapModal",
        props: ['data', 'sources'],
        methods: {
            save () {
                // Проверка заполнености данных
                let flag = false
                this.data.values.map(item => {
                    if (!item.prod_score || !item.not_prod_score) {
                        flag = true
                    }
                })
                if (flag) {return swal.fire('Данные не заполнены', '', 'warning')}
                this.data.prom.res(this.data)
            }
        }
    }
</script>

<style scoped>

    div {

    }
    .border {
        border: solid 1px;
        padding: 12px
    }

</style>