<template>
    <div>
        <table class="table">
              <thead>
                <tr class="text-center">
                  <th class="tabs" :class="{active: tab=='balance'}" @click="tab='balance'">Баланс</th>
                  <th class="tabs" :class="{active: tab=='ofr'}" @click="tab='ofr'">ОФР</th>
                  <th style="max-width: 10px">
                    <button class="btn btn-success" @click="saveBalance">
                        Сохранить
                    </button>
                  </th>
                </tr>
              </thead>
        </table>
        <balance-table v-if="tab=='balance'" :balance="balanceofr.balance"></balance-table>
        <ofr-table v-if="tab=='ofr'" :balance="balanceofr.balance"></ofr-table>
    </div>
</template>

<script>
    import BalanceTable from "./BalanceTable";
    import OfrTable from "./OfrTable";

    export default {
        name: "BalanceOfr",
        props: ['balanceofr'],
        data () {
            return {
                tab: 'balance'
            }
        },
        created () {
        },
        components: {
            'balance-table': BalanceTable,
            'ofr-table': OfrTable,
        },
        methods: {
            saveBalance () {
                LoadStart()
                axios.post('/api/count/balance/save', {balance: this.balanceofr.balance.arr, inn: this.balanceofr.inn}).then(() => {
                    this.$emit('changetab', 'aspects')
                }).catch(() => {
                    swal.fire('Технические проблемы', 'Не сохранено', 'error')
                }).finally(() => {
                    LoadStop()
                })
            }
        }
    }
</script>ddd9c2

<style scoped>
    .active {
        background-color: #a2d2ef
    }

    .tabs:hover {
        cursor: pointer;
        border: solid 1px;
    }

</style>