<template>
    <div>

      <table width="100%" style="text-align: center" border="1px">
          <tr>
              <th rowspan="2">Наименование статей баланса</th>
              <th rowspan="2" class="codeColor">Код</th>
              <th rowspan="2">{{balance.arr[0].date.slice(0,1)}}/{{balance.arr[0].date.slice(10,14)}}</th>
              <th rowspan="2" class="percentColor">Уд.вес</th>
              <th rowspan="2">{{balance.arr[1].date.slice(0,1)}}/{{balance.arr[1].date.slice(10,14)}}</th>
              <th rowspan="2" class="percentColor">Уд.вес</th>
              <th rowspan="2">{{balance.arr[2].date.slice(0,1)}}/{{balance.arr[2].date.slice(10,14)}}</th>
              <th rowspan="2" class="percentColor">Уд.вес</th>
              <th rowspan="2">{{balance.arr[3].date.slice(0,1)}}/{{balance.arr[3].date.slice(10,14)}}</th>
              <th rowspan="2" class="percentColor">Уд.вес</th>
              <th rowspan="2">{{balance.arr[4].date.slice(0,1)}}/{{balance.arr[4].date.slice(10,14)}}</th>
              <th rowspan="2" class="percentColor">Уд.вес</th>
              <th colspan="2">Прирост по сравнению с предыдущим периодом</th>
          </tr>
          <tr>
              <th>тыс.</th>
              <th>%</th>
          </tr>
          <tr class="titles">
              <th colspan="14" class="text-center"><h5>Отчет о финансовых результатах</h5></th>
          </tr>
          <tr v-for="item in balance.codes" v-if="item && item && item[2][0] === '2'">
              <th>{{item[1]}}</th>
              <th class="codeColor">{{item[2]}}</th>
              <th>{{balance.arr[0][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[0][item[0]], balance.arr[0]['code2110'])}}</th>
              <th>{{balance.arr[1][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[1][item[0]], balance.arr[1]['code2110'])}}</th>
              <th>{{balance.arr[2][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[2][item[0]], balance.arr[2]['code2110'])}}</th>
              <th>{{balance.arr[3][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[3][item[0]], balance.arr[3]['code2110'])}}</th>
              <th>{{balance.arr[4][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[4][item[0]], balance.arr[4]['code2110'])}}</th>
              <th>{{minus(balance.arr[4][item[0]], balance.arr[3][item[0]])}}</th>
              <th>{{countPercent(balance.arr[4][item[0]], balance.arr[3][item[0]])}}</th>
          </tr>
      </table>
    </div>
</template>

<script>
    export default {
        name: "OfrAnalytic",
        props: ['balance'],
        created () {
        },
        methods: {
            countPercent (item, sum) {
                let output = NaN
                try {
                    output = (parseFloat(item) / parseFloat(sum) * 100).toFixed(2)
                } catch (e) {}
                if (!isNaN(output) ){
                    return output + '%'
                } else {
                    return ' - '
                }
            },
            minus (var1, var2) {
                let output = NaN
                try {
                    output = parseFloat(var1) - parseFloat(var2)
                } catch (e) {}
                if (!isNaN(output) ){
                    return output
                } else {
                    return ' - '
                }
            }
        },


    }
</script>

<style scoped>

    .titles {
        background-color: #ddd9c3 !important;
    }
    .percentColor {
        background-color: #c6d9f1 !important;
    }
    .codeColor {
        background-color: #fde9d9 !important;
    }

</style>