<template>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <table class="table table-bordered text-center" style="margin-top: 30px">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">#</th>
                  <th scope="col" colspan="2">Диапазон баллов</th>
                  <th scope="col" rowspan="2">Финансовое положение</th>
                  <th scope="col" rowspan="2"></th>
                </tr>
                <tr>
                    <th>От</th>
                    <th>До</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in structure.ratings">
                    <th>{{index + 1}}</th>
                    <th><input type="number" class="form-control" v-model="item.start"> </th>
                    <th><input type="number" class="form-control" v-model="item.end"> </th>
                    <th><input type="text" class="form-control" v-model="item.value" disabled> </th>
                    <th>
                        <span class="btn badge badge-danger" style="margin-top:7px" @click="structure.ratings.splice(index, 1)">
                            <font-icons icon="trash"></font-icons>
                        </span>
                    </th>
                </tr>
              </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    export default {
        name: "RaitingTable",
        props: ['structure'],
        created () {
            if (this.structure.ratings.length == 0) {
                this.structure.ratings = [
                        {start: 0, end: 15, value: 'Хорошее'},
                        {start: 15, end: 112, value: 'Среднее'},
                        {start: 112, end: 500, value: 'Плохое'}]
                }
            }
        }
</script>

<style scoped>

</style>

