<template>
    <div class="container-fluid">
        <div class='row'>
            <div class="col-md-4"></div>
            <div class="col-md-4 text-center">
                <label>Выберете методику расчета</label>
                <select class="form-control" v-model="curMethodologic" @change="getcurMethData">
                    <option v-for="item in methodologies" :value="item.id">{{item.name}}</option>
                </select>
                <br>
                <br>
            </div>
            <div class="col-md-4"></div>

        </div>
        <div v-if="curMethodologic">
            <indicators :structure="structure"></indicators>
            <aspects :structure="structure"></aspects>
            <downfactors :structure="structure"></downfactors>
            <rating :structure="structure"></rating>
            <source-table :structure="structure"></source-table>
        </div>

    </div>
</template>

<script>
    import Indicator from "./Indicator";
    import Aspect from "./Aspect";
    import Downfactor from "./Downfactor";
    import Rating from "./Rating";
    import Source from "./Source";
    export default {
        name: "Show",
        created () {
            this.getMaps()
        },
        data () {
            return {
                curMethodologic:  null,
                methodologies: [],
                structure: {},
            }
        },
        components: {
            'indicators': Indicator,
            'aspects': Aspect,
            'downfactors': Downfactor,
            'rating': Rating,
            'source-table': Source
        },
        methods: {
            getMaps () {
                LoadStart ()
                axios.get('/api/map/list').then(response => {
                    this.methodologies = response.data
                }).catch(() =>{
                    swal.fire('Технические проблемы', '', 'error')
                }).finally(() => {
                    LoadStop()
                })
            },
            getcurMethData () {
                LoadStart()
                axios.get('/api/map', {params: {id: this.curMethodologic}}).then(response => {
                    this.structure = response.data
                }).catch(() => {
                    swal.fire('Технические проблемы', '', 'error')
                }).finally(() =>{
                    LoadStop()
                })
            }
        }
    }
</script>

<style scoped>

</style>