<template>
    <div class="container-fluid">
        <table class="table table-striped" v-for='elem in structure.aspect_blocks'>
          <thead class="thead-dark">
            <tr>
              <th colspan="4">{{elem.name}} <input type="number" placeholder="Вес подгруппы" class="form-control" v-model="elem.weight"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='item in elem.aspects'>
              <th><input type="checkbox" v-model="item.active"></th>
              <th scope="row">{{item.name}} </th>
              <th><input type="number" class="form-control" placeholder="Вес" v-model="item.weight"></th>
              <th class="btn badge badge-info icon" @click="openModal(item)">
                  <font-icons icon="cogs"></font-icons>
              </th>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>

    export default {
        name: "Aspect",
        props: ['structure'],
        mounted () {},
        data () {
          return {
              opened: false,
              component: '',
              title: '',
              item: {}
          }
        },
        methods: {
            openModal (item) {
                Modal.open({component: 'aspect_modal', data: item, width: '1600px'}).then(() => {
                    Modal.close()
                })
            }
        },
    }
</script>

<style scoped>

    .icon {
        margin-top: 11px
    }

</style>