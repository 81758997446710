<template>
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <h3>{{data.name}}</h3>
                <hr>
            </div>
            <div class="col-md-2">
                <button class="btn-lg btn-danger" @click="close">Закрыть</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 border"><b>Наименование показателя</b></div>
            <div class="col-md-6 border text-center"><b>Значение критериев</b></div>
        </div>
        <div class="row" v-for="(item, index) in data.items">
            <div class="col-md-6 border">
                <div class="row">
                    <div class="col-md-12">
                        <textarea v-model="item.name" class="form-control"></textarea>
                    </div>
                    <div class="col-md-12 text-center">
                        <br>
                        <br>
                        <button class="btn btn-danger" @click="data.items.splice(index, 1)">Удалить</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 border">
                <div class="row" v-for="(elem, index2) in item.values">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <textarea disabled v-model="elem.value" class="form-control"></textarea>
                    </div>
                    <div class="col-md-2">
                        <span class="btn badge badge-danger icons" style="margin-top:7px" @click="item.values.splice(index2, 1)">
                            <font-icons icon="trash"></font-icons>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-9 margindiv">
                        <textarea class="form-control" placeholder="Значение" v-model="item.newElem"></textarea>
                    </div>
                    <div class="col-md-1 margindiv">
                        <span class="btn badge badge-success" style="margin-top:7px" @click="item.values.push({value: item.newElem}); item.newElem = ''">
                            <font-icons icon="plus"></font-icons>
                        </span>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center">
                <br>
                <button class="btn btn-primary" @click="data.items.push({values: []})">Добавить показатель</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AspectMapModal",
        props: ['data'],
        methods: {
            close () {
                Modal.close()
            }
        }

    }
</script>

<style scoped>

    .margindiv {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .border {
        border: solid 1px;
        padding: 12px
    }

</style>