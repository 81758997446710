<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <hr>
                <h4>Финансовые показатели</h4>
                <hr>
            </div>
        </div>
        <table class="table table-striped" v-for="block in structure.indicator_blocks">
          <thead class="thead-dark">
            <tr>
              <th colspan="4">{{block.name}} <input type="number" placeholder="Вес подгруппы" v-model='block.weight' class="form-control" disabled></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='item in block.indicators'>
              <th></th>
              <th scope="row">{{item.name}} </th>
              <th><input type="number" class="form-control" placeholder="Вес" v-model="item.weight" disabled></th>
              <th class="btn badge badge-info icon" @click="openModal(item)">
                  <font-icons icon="cogs"></font-icons>
              </th>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "Indicator",
        props: ['structure'],
        methods: {
            openModal (item) {
                item.sources = this.sources
                Modal.open({component: 'indicator_show_modal', data: item, width: '1200px'}).then(data => {
                    Modal.close()
                })
            }
        },
    }
</script>

<style scoped>

    .icon {
        margin-top: 11px
    }

</style>