<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>{{data.name}}</h3>
                <hr>
            </div>
            <div class="col-md-12 text-center">
                <div class="row">
                    <div class="col-md-6 border">
                        <b>Описание:</b>
                        <p><textarea class="form-control" v-model="data.description"></textarea></p>
                    </div>
                    <div class="col-md-6 border">
                        <b>Формула:</b>
                        <p><input type="text" v-model="data.formula" class="form-control"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row text-center">
                    <div class="col-md-6 border">
                        <b>Источник:</b>
                        <select v-model="data.source" class="form-control" disabled>
                            <option :value='item.id' v-for="item in data.sources">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 border">
                        <b>Описание формулы:</b>
                        <p><textarea class="form-control" v-model="data.formula_description"></textarea></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center border">
                <b>Расчет показателя</b>
                <input type="text" class="form-control" v-model="data.count_formula">
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4 text-center">
                <label><b>Вид деятельности</b></label>
                <select class="form-control" v-model="curActivity">
                    <option></option>
                    <option v-for="activity in data.activities" :value="activity.name">{{activity.name}}</option>
                </select>
                <br>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-12 text-center">
                <b>Нормативные значение</b>
                <div class="row">
                    <div class="col-md-6 border">Интервалы</div>
                    <div class="col-md-5 border">Балл</div>
                </div>
                <div class="row" v-for="(item, index) in data.values" v-if="item.activity === curActivity || !item.activity && !curActivity">
                    <div class="col-md-6 border">
                        <div class="row">
                            <div class="col-md-5">
                                <input type="number" step="any" class="form-control" placeholder="Начало" v-model="item.min">
                            </div>
                            <div class="col-md-5">
                                <input type="number" step="any" class="form-control" placeholder="Конец" v-model="item.max">
                            </div>
                            <div class="col-md-2">
                                <span class="btn badge badge-danger" @click='data.values.splice(index, 1)' style="margin-top:7px">
                                    <font-icons icon="trash"></font-icons>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 border" >
                        <input type="number" step="any" class="form-control" v-model="item.score" placeholder="Балл">
                    </div>
                    <div class="col-md-1">
                        <span class="btn badge badge-danger" :class='{btn_opacity: !item.downfactors.bad}' @click="item.downfactors.bad = !item.downfactors.bad">
                            Плохой
                        </span>
                        <span class="btn badge badge-warning" :class='{btn_opacity: !item.downfactors.normal}' @click="item.downfactors.normal = !item.downfactors.normal">
                            Средний
                        </span>
                        <span class="btn badge badge-success" :class='{btn_opacity: !item.downfactors.good}' @click="item.downfactors.good = !item.downfactors.good">
                            Хороший
                        </span>
                        <br>
                        <br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 border">
                        <button class="btn btn-primary" @click="data.values.push({downfactors: {bad: true, good:true, normal: true}, activity: curActivity})">Добавить</button>
                    </div>
                </div>
                <br>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <span class="btn btn-info" @click="close">Отмена</span>
            </div>
            <div class="col-md-4">
                <span class="btn btn-success" @click="data.prom.res(data)">Сохранить</span>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LiquidModal",
        props: ['data'],
        data () {
            return {
                curActivity: false
            }
        },
        methods: {
            close () {
                Modal.close()
            },
        }
    }
</script>

<style scoped>

    .border {
        border: solid 1px;
        padding: 12px
    }

    .btn_opacity {
        opacity: 0.1;
    }

</style>