<template>
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <h3>{{data.name}}</h3>
                <hr>
            </div>
            <div class="col-md-2">
                <button class='btn-lg btn-danger' @click="close">Закрыть</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 border"><b>Наименование показателя</b></div>
            <div class="col-md-5 border text-center"><b>Значение критериев / Балл</b></div>
            <div class="col-md-3 border text-center"><b>Вес подгруппы</b></div>
        </div>
        <div class="row" v-for="(item, index) in data.items">
            <div class="col-md-4 border">{{item.name}}</div>
            <div class="col-md-5 border">
                <div class="row" v-for="(elem, index2) in item.values">
                    <div class="col-md-5 margindiv">
                        <textarea class="form-control" v-model="item.values[index2].value" disabled>{{elem.value}}</textarea>
                    </div>
                    <div class="col-md-4 margindiv">
                        <input type="number" v-model='item.values[index2].score' placeholder="Балл" class="form-control" disabled>
                    </div>
                    <div class="col-md-3 text-center margindiv">
                        <span class="btn badge badge-danger" :class='{btn_opacity: !elem.downfactors.bad}'>
                            Плохой
                        </span>
                        <span class="btn badge badge-warning" :class='{btn_opacity: !elem.downfactors.normal}'>
                            Средний
                        </span>
                        <span class="btn badge badge-success" :class='{btn_opacity: !elem.downfactors.good}'>
                            Хороший
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-md-3 border margindiv">
                 <input class="form-control" placeholder="Вес" type="number" v-model="item.weight" disabled>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "AspectShowModal",
        props: ['data'],
        data () {
            return {
                newElem: ''
            }
        },
        methods: {
            close () {
                Modal.close()
            },
        }
    }
</script>

<style scoped>

    .margindiv {
        margin-top: 15px
    }
    .border {
        border: solid 1px;
        padding: 12px
    }
    .btn_opacity {
        opacity: 0.1
    }

</style>