<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <hr>
                <h4>Понижающие факторы</h4>
                <hr>
            </div>
        </div>
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr><th colspan="2" class="text-center">До среднего (все)</th></tr>
        </thead>
        <tbody v-for='elem in downfactors.normal'>
          <tr>
            <th> {{elem.name}}</th>
            <th> {{elem.value}}</th>
          </tr>
        </tbody>
        <thead class="thead-dark" >
          <tr><th colspan="2" class="text-center">До среднего (прочее)</th></tr>
        </thead>
        <tbody v-for='elem in structure.downfactors' v-if="elem.type==='normal'">
          <tr>
            <th> {{elem.name}}</th>
            <th> {{elem.value}}</th>
          </tr>
        </tbody>
      </table>
      <br>
      <hr>
      <br>
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr><th colspan="2" class="text-center">До плохого (все)</th></tr>
        </thead>
        <tbody v-for='elem in downfactors.bad'>
          <tr>
            <th> {{elem.name}}</th>
            <th> {{elem.value}}</th>
          </tr>
        </tbody>
        <thead class="thead-dark" >
          <tr><th colspan="2" class="text-center">До плохого (прочее)</th></tr>
        </thead>
        <tbody v-for='elem in structure.downfactors' v-if="elem.type==='bad'">
          <tr>
            <th> {{elem.name}}</th>
            <th> {{elem.value}}</th>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script>

    export default {
        name: "DownFactors",
        props: ['structure'],
        data () {
          return {
            downfactors: {normal: [], bad: []},
          }
        },
        mounted () {
            this.getDownfactors()
        },
        methods: {
            getDownfactors () {
              console.log(this)
                this.downfactors = {normal: [], bad: []}
                this.structure.indicator_blocks.map(item => {
                    item.indicators.map(elem => {
                      if (elem.active) {
                        elem.values.map(j => {
                          if (j.downfactors.good === false && j.downfactors.normal === true && j.downfactors.bad === true ) {
                            this.downfactors.normal.push({name: elem.name, value: j.min + ' - ' + j.max})
                          } else if (j.downfactors.good === false && j.downfactors.normal === false && j.downfactors.bad === true) {
                            this.downfactors.bad.push({name: elem.name, value: j.min + ' - ' + j.max})
                          }
                        })
                      } else {

                      }
                    })
                })
              console.log('Привет')
                this.structure.aspect_blocks.map(item => {
                    item.aspects.map(elem => {
                      if (elem.active) {
                        elem.items.map(i => {
                          i.values.map(j => {
                            if (j.downfactors.good === false && j.downfactors.normal === true && j.downfactors.bad === true) {
                              this.downfactors.normal.push({name: i.name, value: j.value})
                            } else if (j.downfactors.good === false && j.downfactors.normal === false && j.downfactors.bad === true) {
                              this.downfactors.bad.push({name: i.name, value: j.value})
                            }
                          })
                        })
                      }
                    })
                })
              console.log(this.downfactors)
            }
        },
    }
</script>

<style scoped>

    .icon {
        margin-top: 5px
    }

    .addBtn {
        background: #28a744;
        pointer: cursor
    }
    .addBtn:hover {
        cursor: pointer;
        border: solid 2px
    }


</style>