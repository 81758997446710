<template>
    <div class="container-fluid">
        <div class="row justify-content-end">
            <div class="col-3" id="myCheck">
                <input type="checkbox" v-model="structure.trend">
                Использовать тенденцию
            </div>
        </div>
        <div class="row" v-if="structure.trend">
        <table class="table table-striped" v-for='block in structure.indicator_blocks' v-if="block.indicators.filter(item => {return item.active}).length > 0">
          <thead>
            <tr><th><h4>{{block.name}}</h4></th></tr>
          </thead>
          <tbody v-for="indicator in block.indicators" v-if="indicator.active">
            <tr>
                <th>{{indicator.name}}</th>
                <th>
                    <input type="number" class="form-control" placeholder="Положительная тенденция" v-model="indicator.trend_plus">
                    <input type="number" class="form-control" placeholder="Отрицательная тенденция" v-model="indicator.trend_minus">
                </th>
            </tr>
          </tbody>
        </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Trend",
        props: ['structure'],
    }
</script>

<style scoped>

    #myCheck {
        border: solid 1px;
        padding: 10px;
    }

</style>