import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import vSelect from 'vue-select'
window.$ = jQuery

import 'popper.js'
import 'bootstrap'
import './assets/app.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as fas from '@fortawesome/free-solid-svg-icons'
import Modal from './class/Modal.js'
global.Modal = Modal
import axios from 'axios'
global.axios = axios
import swal from 'sweetalert2'
global.swal = swal

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas.faCogs)
library.add(fas.faTrash)
library.add(fas.faPlus)
library.add(fas.faArrowCircleUp)
library.add(fas.faArrowCircleDown)
library.add(fas.faEdit)
library.add(fas.faArrowAltCircleLeft)

Vue.component('font-icons', FontAwesomeIcon)
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

global.MainVue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


global.LoadStart = function () {
  try {
    document.getElementById('loader').style.display = ""
    document.getElementById('lds-ring').style.display = ""
  } catch (e) {}
}

global.LoadStop  = function () {
  try {
    document.getElementById('loader').style.display = "none"
    document.getElementById('lds-ring').style.display = "none"
  } catch (e) {}
}


