<template>
	<div class="mpage">

		<div v-if="opened" >

			<div class="mpage_block" style="background-color: #343b48" :class="{open_animation: page.in_open, close_animation: page.close}" >

				<div v-if="page.type == 'standart' && use_header" class="mpage_block_scroll">

					<div class="spHead panSpHead">
						<button class="btn btn-panel btn-circle btn-lg panSpHeadBut">
							<i class="fa fa-chevron-left"></i>
							<span class="btn btn-danger">
								<font-icons icon="arrow-alt-circle-left"></font-icons>
							</span>
                        </button>
						<div class="spHeadHeader panSpHeadHeader mt5">
							<span>{{page.title}}</span>
						</div>
					</div>
					<div class="spContent" >
						<component :is="page.component" :data="page.data" ></component>
					</div>
				</div>

				<component v-else :is="page.component" :data="page.data"  ></component>


			</div>

		</div>

	</div>
</template>

<script>

    import HelloWorld from '@/components/HelloWorld'

	export default{

		data(){
			return {
				page: false,
				pages: [],
				opened: false,
				counter: 0,
				stack: [],
				use_header: false,
			}
		},
		created(){

			Modal.ModalMpage = this;

		},
		components:{
			'find-product': HelloWorld
		},
		methods:{

			open({type = 'standart', title = 'Карта', data = {}, component = '', use_header = true} = {}){

				let same = ( this.page && this.page.component == component );

				this.use_header = use_header;
				this.page = { type, title, data, component, use_header, in_open: true };
				this.opened = true;

				if(same){
					this.page.component = '';
					Vue.nextTick(() => {
					  this.page.component = component;
					});
				}

			},
			closeAll({fast = false} = {}){
				this.opened = false;
			},
			closeLast(){
				this.opened = false;
			}

		},
		computed:{

		}

	}

</script>

<style lang="scss">


	.btn-circle {
	  width: 30px;
	  height: 30px;
	  padding: 6px 0;
	  border-radius: 15px;
	  text-align: center;
	  font-size: 12px;
	  line-height: 1.428571429;
	}
	.btn-circle.btn-lg {
	  	width: 40px;
		height: 40px;
		padding: 10px 10px;
		border-radius: 20px;
		font-size: 18px;
		line-height: 1.33;
	}
	.btn-circle.btn-xl {
	  width: 70px;
	  height: 70px;
	  padding: 10px 16px;
	  border-radius: 35px;
	  font-size: 24px;
	  line-height: 1.33;
	}

	.btn-panel{
	    background: #444854;
		border: 1px solid #434652;
	}




	.mpage {
		position: relative;
		overflow: hidden;
		.mpage_block{
		top: 0px;
		right: 0px;
		position: fixed;
		height: 100%;
		width: 700px;
		background-color: #fff;
		box-shadow: 0 0 9px rgba(0,0,0,0.3);
		z-index: 1005;
	    overflow-x: hidden;
	    overflow-y: scroll;
		.spContent{
			padding: 20px;
		}

		.panSpHead{
			position: relative;
		    height: 80px;
		    background-color: #4f5467;
		    text-align: center;
		    padding-top: 25px;
		    .panSpHeadHeader{
			    font-size: 25px;
			    color: #fff;
		    }
		    .panSpHeadHeaderSm{
			    font-size: 15px;
				margin-top: -5px;
		    }

		}


		&.mpage_not_active{
			pointer-events: none;
		}

		.mpage_block_scroll{
			position: absolute;
			height: 100%;
			width: 100%;
			overflow: scroll;
			-webkit-overflow-scrolling:touch;
			height: 100%;
		}

		&.close_animation{
			animation: close_mpage 0.6s;
		}

		&.open_animation{
			animation: open_mpage 0.6s;
		}

		&.next_an{
			animation: next_an 0.6s;
		}

		&.next_back_an{
			animation: next_back_an 0.6s;
		}

	}
	}


	@keyframes  open_mpage {
		0%{
			transform:translateX(100%)
		}
		80% {
			transform:translateX(0%)
		}
		100% {
			transform:translateX(0%)
		}
	}


	@keyframes  close_mpage {
		0%{
			transform:translateX(0%)
		}
		80% {
			transform:translateX(100%)
		}
		100% {
			transform:translateX(100%)
		}
	}


.panSpHeadBut{
     position: absolute;
     left: 20px;
     top: 20px;
    }


</style>






