<template>
    <div>
        <div id="loader" style="display: none"></div>
        <div id="lds-ring" style="display:none"><div></div><div></div><div></div><div></div></div>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>

</style>