<template>
    <div>
      <table class="table table-striped" style="border: solid 1px">
        <thead class="thead-dark">
          <tr><th class="text-center" colspan="2">Виды деятельности</th></tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in activities">
            <th>
                <div class="row">
                    <div class="col-md-4">
                        <input type="text" class="form-control" v-model="item.name">
                    </div>
                </div>
                <hr>
                <div class="row" v-for="(elem, index2) in item.items">
                    <div class="col-md-11">
                        <input type="text" class="form-control" v-model="elem.name">
                        <br>
                    </div>
                    <div class="col-md-1">
                        <span class="btn badge badge-danger" style="margin-top:7px" @click="item.items.splice(index2, 1)">
                            <font-icons icon="trash"></font-icons>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-11">
                        <input type="text" v-model="item.newItem" class="form-control" placeholder="Наименование вида деятельности">
                    </div>
                    <div class="col-md-1">
                        <span class="btn badge badge-success" style="margin-top:7px" @click="item.items.push({name: item.newItem}); item.newItem = ''">
                            <font-icons icon="plus"></font-icons>
                        </span>
                    </div>
                </div>
            </th>
            <th class="text-center">
                <span class="btn btn-danger" @click="activities.splice(index,1)">
                    <font-icons icon="trash"></font-icons>
                </span>
            </th>
          </tr>
          <tr class="text-center">
              <th colspan="2">
                <br>
                <input type="text" class="form-control" placeholder="Наименование группы" v-model="newElem">
                <br>
                <button class="btn btn-primary" @click="activities.push({name: newElem, items: []}); newElem = ''">Добавить</button>
              </th>
          </tr>
        </tbody>
      </table>
      <div class="row">
          <div class="col-md-12 text-center">
            <button class="btn btn-success" @click="save">Сохранить</button>
          </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "ActivityGenerator",
        data () {
            return {
              activities: [],
              newElem: ''
            }
        },
        created () {
            this.getActivities()
        },
        methods: {
            getActivities() {
                LoadStart()
                axios.get('/api/structure/activities').then(response => {
                    this.activities = response.data
                }).catch(() => {
                    swal.fire('Технические проблемы', 'Виды деятельности не загрузились', 'error')
                }).finally(() => {
                    LoadStop()
                })
            },
            save () {
                LoadStart()
                axios.post('/api/structure/activities', {data: this.activities}).then(() => {
                    swal.fire('Сохранено', '', 'success')
                    this.$router.push('/')
                }).catch(() => {
                    swal.fire('Технические ошибки', 'Факторы не сохранились', 'error')
                }).finally(() => {
                    LoadStop(

                    )
                })
            }
        }
    }
</script>

<style scoped>

</style>