<template>
 <div>

  <template v-if="opened" >
   <div @click="onclose" ref="main_close" class="modal show" tabindex="-1" style="display: block; padding-left: 0px;">
     <div v-for="(page,key) in pages" :key="page.token" @click="orClose(key, $event)" :class="{ mod_opacity: key != 0 }" class="modal-dialog modal-center" id="modal-dialog-desktop-center" :style="{width: page.width, 'max-width': page.width}">
       <div class="modal-content modal_mpage" :style="{'min-height': page.min_height }" >
        <div class="modal-header" v-if="page.title">
         <h5 class="modal-title">{{page.title}}</h5>
     </div>

        <div class="modal-body"  >
            <component ref="childs" :is="page.component" :data="page.data"></component>
            <br>
            <br>
        </div>
       </div>
     </div>
   </div>
   <div class="modal-backdrop in show" ></div>

  </template>

 </div>
</template>

<script>

    import ReqModal from '@/components/reg/ReqModal'
    import AspectModal from '@/components/reg/AspectModal'
    import BalanceAnalytic from '@/components/count/BalanceAnalytic'
    import BalanceOfrModal from '@/components/count/BalanceOfrModal'
    import AspectMapModal from "../generator/AspectMapModal";
    import IndicatorMapModal from "../generator/IndicatorMapModal";
    import DownfactorMapModal from "../reg/DownfactorMapModal";
    import IndicatorShowModal from "../show/IndicatorShowModal";
    import AspectShowModal from "../show/AspectShowModal";
    import IndicatorsCountModal from "../count/IndicatorsCountModal";
    import TrendCountModal from "../count/TrendCountModal";

	export default{

		data(){
			return {
				pages: [],
				opened: false,
				can_close: true,
				need_back: true,
			}
		},
		components:{
			'req_modal': ReqModal,
			'aspect_modal': AspectModal,
			'balance_analytic': BalanceAnalytic,
            'balance_ofr_modal' : BalanceOfrModal,
            'aspectmap_modal': AspectMapModal,
            'indicatormap_modal': IndicatorMapModal,
            'add_downfactor': DownfactorMapModal,
            'indicator_show_modal': IndicatorShowModal,
            'aspect_show_modal': AspectShowModal,
            'indicator_count_modal': IndicatorsCountModal,
            'trend_count_modal': TrendCountModal
		},
        props: ['data'],
		methods:{

			open({data = {}, component = '', can_close = true, width = "600px", min_height = '300px', background = 'white', need_back = false, title = false, prom} = {}){
				data.prom = prom;
				if(!component)
					throw new Error('Неверный компонент');

				this.counter++;
				this.opened = true;
				let token = (new Date()).toISOString();
				this.pages.unshift({data, component, can_close, width, min_height, need_back, background, title, prom, token});

				document.querySelector('body').classList.add('modal-open');
			},


			close(){
				this.pages.shift();
				if(this.pages.length == 0){
					this.opened = false;
					document.querySelector('body').classList.remove('modal-open');
				}



			},
			onclose(e){
				if(!this.pages.can_close)
					return;
				if (e.target == this.$refs.main_close){
					if(this.$refs.childs[0])
						this.$refs.childs[0].$emit('modal-close');
					this.close();
				}
			},
			orClose(key, event){
				if(key != 0)
					this.close()

			},
            save () {

            }
		},
        created () {
          Modal.ModalVue = this
        },
		computed:{

		}

	}

</script>

<style lang="scss">
	.modal-body {
		padding: 15px 15px 0;
	}
	.mod_opacity{
		opacity: 0.3
	}
</style>
