<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <br>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4 text-center"><h4>Баланс и ОФР</h4></div>
                    <div class="col-md- 4 text-center">
                        <button class="btn btn-danger" @click="closeModal">Закрыть</button>
                    </div>
                    <div class="col-md-12"><hr></div>
                </div>
            </div>
        </div>
        <table class="table">
              <thead>
                <tr class="text-center">
                  <th class="tabs" :class="{active: tab=='balance'}" @click="tab='balance'">Баланс</th>
                  <th class="tabs" :class="{active: tab=='ofr'}" @click="tab='ofr'">ОФР</th>
                </tr>
              </thead>
        </table>
        <balance v-if="tab=='balance'" :balance="data.balanceofr.balance"></balance>
        <ofr v-if="tab=='ofr'" :balance="data.balanceofr.balance"></ofr>
    </div>
</template>

<script>
    import BalanceAnalytic from "./BalanceAnalytic";
    import OfrAnalytic from "./OfrAnalytic";
    export default {
        name: "BalanceOfrModal",
        props: ['data'],
        data () {
            return {
                tab: 'balance',
            }
        },
        components: {
            'balance': BalanceAnalytic,
            'ofr': OfrAnalytic
        },
        methods: {
            closeModal () {
                Modal.close()
            }
        }
    }
</script>

<style scoped>

    .active {
        background-color: #a2d2ef
    }

    .tabs:hover {
        cursor: pointer;
        border: solid 1px;
    }

</style>