<template>
    <div>
        <div class="row" id="choice">
            <div class="col-md-4 text-center">
                <label>Выберете методику расчета</label>
                <select class="form-control" v-model="curMethodologic">
                    <option v-for="item in methodologies" :value="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="col-md-4 text-center">
                <label>Введите ИНН</label>
                <input type="text" class="form-control" placeholder="ИНН" v-model="curInn">
                <br>
                <button class="btn btn-primary" @click="getData" v-if="curPeriod && curMethodologic && curInn">Получить данные</button>
            </div>
            <div class="col-md-4 text-center">
                <label>Выберете отчетный период</label>
                <select class="form-control" v-model="curPeriod">
                    <option v-for="item in periods">{{item.name}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ChoiceInn",
        props: ['data'],
        data () {
            return {
                methodologies: [],
                curMethodologic: {},
                curInn: '',
                curPeriod: '',
                periods: []

            }
        },
        created () {
            this.getPeriods()
            this.getMethodologies()
        },
        methods: {
            getMethodologies () {
                LoadStart()
                axios.get('/api/map/list').then(response => {
                    this.methodologies = response.data
                }).catch(() =>{
                    swal.fire('Технические проблемы', '', 'error')
                }).finally(() => {
                    LoadStop()
                })
            },
            getPeriods () {
                for (let i = 1; i < 6; i++){
                    let date = new Date()
                    date.setMonth(date.getMonth() - i * 3)
                    let quarter = Math.floor((date.getMonth() + 3) / 3)
                    this.periods.push({date: date, name: quarter + ' квартал ' + date.getFullYear()})
                }

            },
            getData () {
                LoadStart()
                axios.post('/api/count/balance/get', {inn: this.curInn, methodolog: this.curMethodologic, date: this.curPeriod}).then(response => {
                    this.data.balanceofr = response.data
                    this.data.balanceofr.inn = this.curInn
                    this.data.curDate = this.curPeriod
                    this.data.methodolog = this.curMethodologic
                    this.$emit('changetab', 'balance-ofr')
                }).catch(() => {
                    swal.fire('Технические проблемы', '', 'error')
                }).finally(() => {
                    LoadStop()
                })
            }
        }
    }
</script>

<style scoped>

 #choice {
     margin-top: 50px
 }

</style>