<template>
    <div id="logo">
        <router-link class="nav-link" tag='li' to="/"><img alt="Vue logo" src="@/assets/logo_sgb.png"></router-link>
    </div>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style scoped>

    #logo {
        margin: 15px
    }

</style>