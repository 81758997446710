<template>
    <div>
        <div class="row" id="window">
            <div class="col-md-12">
                <choice-inn v-if="tab=='choice-inn'" @changetab="updateparent" :data="data"></choice-inn>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-10">
                        <balance-ofr v-if="tab=='balance-ofr'" @changetab="updateparent" :balanceofr="data.balanceofr"></balance-ofr>
                        <aspects v-if="tab=='aspects'" @changetab="updateparent" :data="data"></aspects>
                    </div>
                    <div class="col-md-2">
                        <documents v-if="tab!='choice-inn'" @changetab="updateparent" :data="data"></documents>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChoiceInn from './ChoiceInn.vue'
    import BalanceOfr from './BalanceOfr.vue'
    import Documents from './Documents.vue'
    import Aspects from "./Aspects";

    export default {
        name: "Count",
        data () {
            return {
                tab: 'choice-inn',
                data: {}
            }
        },
        created () {

        },
        components: {
            'choice-inn': ChoiceInn,
            'balance-ofr': BalanceOfr,
            'documents': Documents,
            'aspects': Aspects,
        },
        methods: {
            updateparent(variable) {
                this.tab = variable
            }
        }
    }
</script>

<style scoped>

    #window {
        margin-left: 15px;
        margin-right: 15px;
    }

</style>