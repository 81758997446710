<template>
    <div>
        <div v-if="!mode">
            <div class="container">
              <div id="menu">
                <div class="widget">
                  <ul>
                    <span class="nav-link text-center" @click="getStructure('create')">Создание</span>
                    <hr>
                    <span class="nav-link text-center" @click="getStructure('edit')">Редактирование</span>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        <div v-else>
            <div class="row navbar">
                <div class="col-md-6" v-if="mode === 'edit'">
                    <label>Выберете методику расчета</label>
                    <select class="form-control" v-model="curMethodologic" @change="getcurMethData">
                        <option v-for="item in methodologies" :value="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-md-6" v-else></div>
                <div class="col-md-4">
                    <input type="text" class="form-control" placeholder="Название" v-model="structure.name">
                </div>
                <div class="col-md-2 well">
                    <div class="row">
                        <div class="btn btn-success" style="padding-right: 10px" @click="save">Сохранить</div>
                    </div>
                </div>
            </div>
            <nav class="navbar navbar-light" style="background-color: #e3f2fd;">
              <span class="btn btn-primary" @click="page='indicator'" :class="{curTab: page=='indicator'}">Финансовые показатели</span>
              <span class="btn btn-primary" @click="page='aspect'" :class="{curTab: page=='aspect'}">Финансовые аспекты</span>
              <span class="btn btn-primary" @click="page='downward_factors'" :class="{curTab: page=='downward_factors'}">Понижающие факторы</span>
              <span class="btn btn-primary" @click="page='trend'" :class="{curTab: page=='trend'}">Тенденция</span>
              <span class="btn btn-primary" @click="page='activity'" :class="{curTab: page=='activity'}">Виды деятельности</span>
              <span class="btn btn-primary" @click="page='raiting_table'" :class="{curTab: page=='raiting_table'}">Таблица рейтингов</span>
              <span class="btn btn-primary" @click="page='reporting'" :class="{curTab: page=='reporting'}">Отчетность</span>
            </nav>

            <indicator v-if="page=='indicator'" :structure="structure" :sources="sources"></indicator>
            <aspect v-if="page=='aspect'" :structure="structure"></aspect>
            <downward_factors v-if="page=='downward_factors'" :structure="structure" ></downward_factors>
            <trend v-if="page=='trend'" :structure="structure" ></trend>
            <activity v-if="page=='activity'" :structure="structure" ></activity>
            <raiting_table v-if="page=='raiting_table'" :structure="structure"></raiting_table>
            <reporting v-if="page=='reporting'" :structure="structure"></reporting>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Indicator from './Indicator.vue'
import Aspect from './Aspect.vue'
import DownFactors from './DownFactors.vue'
import RaitingTable from './RaitingTable.vue'
import Reporting from './Reporting.vue'
import Activity from "./Activity";
import Trend from "./Trend";

    export default {
        name: "Reg",
        components: {
            'indicator': Indicator,
            'aspect': Aspect,
            'downward_factors': DownFactors,
            'raiting_table': RaitingTable,
            'reporting': Reporting,
            'activity': Activity,
            'trend': Trend
        },
        data () {
            return {
              structure: {},
              weights: {},
              page: '',
              mode: '',
              methodologies: [],
              curMethodologic: {},
            }
        },
        created () {
            this.getSources()
        },
        methods: {
            getStructure (mode) {
                this.mode = mode
                LoadStart()
                if (mode === 'create') {
                    axios.get('/api/structure', {}).then(response => {
                        this.structure = response.data
                    }).catch(response => {
                        swal.fire('Технические проблемы', '', 'error')
                    }).finally(() => {
                        LoadStop()
                        this.page = 'indicator'
                    })
                } else if (mode === 'edit') {
                    axios.get('/api/map/list').then(response => {
                        this.methodologies = response.data
                    }).catch(() =>{
                        swal.fire('Технические проблемы', '', 'error')
                    }).finally(() => {
                        LoadStop()
                        this.page = 'indicator'
                    })
                }
            },
            getSources () {
                LoadStart()
                axios.get('/api/structure/sources').then(response => {
                    this.sources = response.data
                }).catch(() => {
                    swal.fire('Технические ошибки', 'Источники не сохранились', 'error')
                }).finally(() => {
                    LoadStop()
                })
            },
            save () {
                if (this.validation()) {
                    LoadStart()
                    this.structure['id'] = this.curMethodologic
                    axios.post('/api/map/save', {structure: this.structure}).then(() => {
                        LoadStop()
                        swal.fire('Методика сохранена', '', 'success')
                        this.$router.push('/')
                    }).catch(() => {
                        LoadStop()
                        swal.fire('Технические проблемы', 'методика не сохранена', 'error')
                    })
                }

            },
            validation () {
                if (!this.structure.name) {
                    swal.fire('Напишите название методики', '', 'warning')
                    return false
                }
                return true
            },
            checkRating () {
                let flag = true
                if (!this.structure.ratings || this.structure.ratings.length == 0) {
                    return false
                }
                this.structure.ratings.map(item => {
                    if (isNaN(item.start) || isNaN(item.end) || !item.value) {
                        flag = false
                    }
                })
                return flag
            },
            getcurMethData () {
                LoadStart()
                axios.get('/api/map/edit', {params: {id: this.curMethodologic}}).then(response => {
                    this.structure = response.data['structure']
                }).finally(() =>{
                    LoadStop()
                })
            }

        }
    }
</script>

<style scoped>

    .curTab {
        color: #007bff;
        background-color: white;
    }

    .curTab:hover {
        color: #007bff;
        background-color: white;
    }

</style>