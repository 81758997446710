<template>
    <div class="footer" style="margin-top: 150px; background-color: #243147">

    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>