<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>{{data.name}}</h3>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 border"><b>Наименование показателя</b></div>
            <div class="col-md-5 border text-center"><b>Значение критериев / Балл</b></div>
            <div class="col-md-2 border text-center"><b>Вес подгруппы</b></div>
            <div class="col-md-1 border"></div>
        </div>
        <div class="row" v-for="(item, index) in data.items">
            <div class="col-md-4 border">
                <textarea class="form-control" v-model="item.name"></textarea>
            </div>
            <div class="col-md-5 border">
                <div class="row" v-for="(elem, index2) in item.values">
                    <div class="col-md-5 margindiv">
                        <textarea class="form-control" v-model="item.values[index2].value">{{elem.value}}</textarea>
                    </div>
                    <div class="col-md-3 margindiv">
                        <input type="number" v-model='item.values[index2].score' placeholder="Балл" class="form-control">
                    </div>
                    <div class="col-md-1 margindiv">
                        <span class="btn badge badge-danger" style="margin-top:7px" @click="item.values.splice(index2, 1)">
                            <font-icons icon="trash"></font-icons>
                        </span>
                    </div>
                    <div class="col-md-3 text-center margindiv">
                        <span class="btn badge badge-danger" :class='{btn_opacity: !elem.downfactors.bad}'  @click="elem.downfactors.bad = !elem.downfactors.bad">
                            Плохой
                        </span>
                        <span class="btn badge badge-warning" :class='{btn_opacity: !elem.downfactors.normal}' @click="elem.downfactors.normal = !elem.downfactors.normal">
                            Средний
                        </span>
                        <span class="btn badge badge-success" :class='{btn_opacity: !elem.downfactors.good}'  @click="elem.downfactors.good = !elem.downfactors.good">
                            Хороший
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 margindiv">
                        <input type="text" class="form-control" v-model="item.newElem">
                    </div>
                    <div class="col-md-1 margindiv">
                        <span class="btn badge badge-success" style="margin-top:7px" @click="item.values.push({value: item.newElem, downfactors: {bad:true, good:true, normal:true}}); item.newElem = ''">
                            <font-icons icon="plus"></font-icons>
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-md-2 border margindiv">
                 <input class="form-control" placeholder="Вес" type="number" v-model="item.weight">
            </div>
            <div class="col-md-1 border margindiv">
                <button class="btn-sm btn-danger" @click="data.items.splice(index, 1)">Удалить</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <br>
                <button class="btn btn-primary" @click="data.items.push({values: []})">Добавить Показатель</button>
            </div>
        </div>
        <div class="row margindiv">
            <div class="col-md-12 text-center">
                <span class="btn btn-success" @click="data.prom.res(data)">Сохранить</span>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "AspectModal",
        props: ['data'],
        data () {
            return {
                newElem: ''
            }
        },
        methods: {
            close () {
                Modal.close()
            }
        }
    }
</script>

<style scoped>

    .margindiv {
        margin-top: 15px
    }
    .border {
        border: solid 1px;
        padding: 12px
    }
    .btn_opacity {
        opacity: 0.1
    }

</style>