<template>
    <div>

      <table width="100%" style="text-align: center" border="1px">
          <tr>
              <th>Наименование статей баланса</th>
              <th class="codeColor">Код</th>
              <th>{{balance.arr[0].date.slice(0,1)}}/{{balance.arr[0].date.slice(10,14)}}</th>
              <th class="percentColor">Уд.вес</th>
              <th>{{balance.arr[1].date.slice(0,1)}}/{{balance.arr[1].date.slice(10,14)}}</th>
              <th class="percentColor">Уд.вес</th>
              <th>{{balance.arr[2].date.slice(0,1)}}/{{balance.arr[2].date.slice(10,14)}}</th>
              <th class="percentColor">Уд.вес</th>
              <th>{{balance.arr[3].date.slice(0,1)}}/{{balance.arr[3].date.slice(10,14)}}</th>
              <th class="percentColor">Уд.вес</th>
              <th>{{balance.arr[4].date.slice(0,1)}}/{{balance.arr[4].date.slice(10,14)}}</th>
              <th class="percentColor">Уд.вес</th>
          </tr>
          <tr><th class='text-center elements' colspan="12"><h4>Актив</h4></th></tr>
          <tr class="titles">
              <th colspan="12" class="text-center"><h5>Внеоборотные активы</h5></th>
          </tr>
          <tr v-for="item in balance.codes" v-if="item && item[2].slice(0,2) == '11'">
              <th>{{item[1]}}</th>
              <th class="codeColor">{{item[2]}}</th>
              <th>{{balance.arr[0][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[0][item[0]], balance.arr[0]['code1600'])}}</th>
              <th>{{balance.arr[1][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[1][item[0]], balance.arr[1]['code1600'])}}</th>
              <th>{{balance.arr[2][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[2][item[0]], balance.arr[2]['code1600'])}}</th>
              <th>{{balance.arr[3][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[3][item[0]], balance.arr[3]['code1600'])}}</th>
              <th>{{balance.arr[4][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[4][item[0]], balance.arr[4]['code1600'])}}</th>
          </tr>
          <tr class="titles">
              <th colspan="12" class="text-center"><h5>Оборотные активы</h5></th>
          </tr>
          <tr v-for="item in balance.codes" v-if="item && (item[2].slice(0,2) == '12' || item[2] == '1600')">
              <th>{{item[1]}}</th>
              <th class="codeColor">{{item[2]}}</th>
              <th :class="{dangerColor: item[2] == '1600' && balance.arr[0]['code1600'] != balance.arr[0]['code1700']}">{{balance.arr[0][item[0]]}}</th>
              <th  class="percentColor">{{countPercent(balance.arr[0][item[0]], balance.arr[0]['code1600'])}}</th>
              <th :class="{dangerColor: item[2] == '1600' && balance.arr[1]['code1600'] != balance.arr[1]['code1700']}">{{balance.arr[1][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[1][item[0]], balance.arr[1]['code1600'])}}</th>
              <th :class="{dangerColor: item[2] == '1600' && balance.arr[2]['code1600'] != balance.arr[2]['code1700']}">{{balance.arr[2][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[2][item[0]], balance.arr[2]['code1600'])}}</th>
              <th :class="{dangerColor: item[2] == '1600' && balance.arr[3]['code1600'] != balance.arr[3]['code1700']}">{{balance.arr[3][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[3][item[0]], balance.arr[3]['code1600'])}}</th>
              <th :class="{dangerColor: item[2] == '1600' && balance.arr[4]['code1600'] != balance.arr[4]['code1700']}">{{balance.arr[4][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[4][item[0]], balance.arr[4]['code1600'])}}</th>
<!--              Баланс выводим раздельно          -->
          </tr>
          <tr><th class='text-center elements' colspan="12"><h4>Пасcив</h4></th></tr>
          <tr class="titles">
              <th colspan="12" class="text-center"><h5>Капитал и резервы</h5></th>
          </tr>
          <tr v-for="item in balance.codes" v-if="item && item[2].slice(0,2) == '13'">
              <th>{{item[1]}}</th>
              <th class="codeColor">{{item[2]}}</th>
              <th>{{balance.arr[0][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[0][item[0]], balance.arr[0]['code1700'])}}</th>
              <th>{{balance.arr[1][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[1][item[0]], balance.arr[1]['code1700'])}}</th>
              <th>{{balance.arr[2][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[2][item[0]], balance.arr[2]['code1700'])}}</th>
              <th>{{balance.arr[3][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[3][item[0]], balance.arr[3]['code1700'])}}</th>
              <th>{{balance.arr[4][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[4][item[0]], balance.arr[4]['code1700'])}}</th>
          </tr>
          <tr class="titles">
              <th colspan="12" class="text-center"><h5>Долгосрочные обязательства</h5></th>
          </tr>
          <tr v-for="item in balance.codes" v-if="item && item[2].slice(0,2) == '14'">
              <th>{{item[1]}}</th>
              <th class="codeColor">{{item[2]}}</th>
              <th>{{balance.arr[0][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[0][item[0]], balance.arr[0]['code1700'])}}</th>
              <th>{{balance.arr[1][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[1][item[0]], balance.arr[1]['code1700'])}}</th>
              <th>{{balance.arr[2][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[2][item[0]], balance.arr[2]['code1700'])}}</th>
              <th>{{balance.arr[3][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[3][item[0]], balance.arr[3]['code1700'])}}</th>
              <th>{{balance.arr[4][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[4][item[0]], balance.arr[4]['code1700'])}}</th>
          </tr>
          <tr class="titles">
              <th colspan="12" class="text-center"><h5>Краткосрочные обязательства</h5></th>
          </tr>
          <tr v-for="item in balance.codes" v-if="item && (item[2].slice(0,2) == '15' || item[2] =='1700')">
              <th>{{item[1]}}</th>
              <th class="codeColor">{{item[2]}}</th>
              <th :class="{dangerColor: item[2] == '1700' && balance.arr[0]['code1600'] != balance.arr[0]['code1700']}">{{balance.arr[0][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[0][item[0]], balance.arr[0]['code1700'])}}</th>
              <th :class="{dangerColor: item[2] == '1700' && balance.arr[1]['code1600'] != balance.arr[1]['code1700']}">{{balance.arr[1][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[1][item[0]], balance.arr[1]['code1700'])}}</th>
              <th :class="{dangerColor: item[2] == '1700' && balance.arr[2]['code1600'] != balance.arr[2]['code1700']}">{{balance.arr[2][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[2][item[0]], balance.arr[2]['code1700'])}}</th>
              <th :class="{dangerColor: item[2] == '1700' && balance.arr[3]['code1600'] != balance.arr[3]['code1700']}">{{balance.arr[3][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[3][item[0]], balance.arr[3]['code1700'])}}</th>
              <th :class="{dangerColor: item[2] == '1700' && balance.arr[4]['code1600'] != balance.arr[4]['code1700']}">{{balance.arr[4][item[0]]}}</th>
              <th class="percentColor">{{countPercent(balance.arr[4][item[0]], balance.arr[4]['code1700'])}}</th>
          </tr>
      </table>
    </div>
</template>

<script>
    export default {
        name: "BalanceAnalytics",
        props: ['balance'],
        created () {
        },
        methods: {
            countPercent (item, sum) {
                let output = NaN
                try {
                    output = (parseFloat(item) / parseFloat(sum) * 100).toFixed(2)
                } catch (e) {}
                if (!isNaN(output) ){
                    return output + '%'
                } else {
                    return ' - '
                }
            }
        },
    }
</script>

<style scoped>

    .titles {
        background-color: #ddd9c3 !important;
    }
    .percentColor {
        background-color: #c6d9f1 !important;
    }
    .codeColor {
        background-color: #fde9d9 !important;
    }

    .elements {
      background-color: #7dff8d;
    }

    .dangerColor {
        background-color: #ff9090;
    }

</style>