import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Reg from '@/components/reg/Reg'
import Count from '@/components/count/Count'
import GeneratorMain from "./components/generator/GeneratorMain"
import Show from "./components/show/Show";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: true
    },
    {
      path: '/reg',
      name: 'reg',
      component: Reg,
      props: true
    },
    {
      path: '/show',
      name: 'show',
      component: Show,
      props: true
    },
    {
      path: '/count',
      name: 'count',
      component: Count,
      props: true
    },
    {
      path: '/generator',
      name: 'generator',
      component: GeneratorMain,
      props: true
    },


  ],


})
