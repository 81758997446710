<template>
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <br>
                <h4>Добавление понижающих факторов</h4>
            </div>
            <div class="col-md-2 text-center">
                <button class="btn-lg btn-danger" @click="data.prom.res()">Закрыть</button>
            </div>
            <div class="col-md-12"><hr></div>
        </div>
        <table class="table table-striped">
            <tbody v-for='(elem, index) in data'>
            <tr>
                <th> {{elem.name}}</th>
                <th>
                    <select v-model="elem.type" class="form-control">
                        <option v-for="item in arr" :value="item.type">{{item.value}}</option>
                    </select>
                </th>
                <th class="text-center">
                    <span class="btn badge badge-danger icons" style="margin-top:7px" @click="data.splice(index, 1)">
                        <font-icons icon="trash"></font-icons>
                    </span>
                </th>
            </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col-md-12 text-center">
                <input type="text" class="form-control" v-model="newElem">
                <br>
                <button class="btn btn-success" @click="data.push({name: newElem})">
                    Добавить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DownfactorMapModal",
        props: ['data'],
        data () {
            return {
                newElem: '',
                arr: [{value: 'До среднего', type: 'normal'}, {value: 'До плохого', type: 'bad'}, {value: 'Не выбран'}]
            }
        }
    }
</script>

<style scoped>

</style>