<template>
    <div class="container">
        <nav class="navbar navbar-light text-center" style="background-color: #e3f2fd;">
          <span class="btn btn-primary" @click="page='indicators'" :class="{curTab: page=='indicators'}">Финансовые показатели</span>
          <span class="btn btn-primary" @click="page='aspects'" :class="{curTab: page=='aspects'}">Финансовые аспекты</span>
          <span class="btn btn-primary" @click="page='downfactors'" :class="{curTab: page=='downfactors'}">Понижающие факторы</span>
          <span class="btn btn-primary" @click="page='activities'" :class="{curTab: page=='activities'}">Виды деятельности</span>
        </nav>
        <aspects v-if="page==='aspects'"></aspects>
        <factors v-if="page==='factors'"></factors>
        <indicators v-if="page==='indicators'"></indicators>
        <downfactors v-if="page==='downfactors'"></downfactors>
        <activities v-if="page==='activities'"></activities>
    </div>
</template>

<script>
    import AspectGenerator from "./AspectGenerator";
    import FactorGenerator from "./FactorGenerator";
    import IndicatorGenerator from "./IndicatorGenerator";
    import DownfactorGenerator from "./DownfactorGenerator";
    import ActivityGenerator from "./ActivityGenerator";

    export default {
        name: "GeneratorMain",
        components: {
            'aspects': AspectGenerator,
            'factors': FactorGenerator,
            'indicators': IndicatorGenerator,
            'downfactors': DownfactorGenerator,
            'activities': ActivityGenerator
        },
        data () {
            return {
                page: 'indicators'
            }
        }
    }
</script>

<style scoped>

    .curTab {
        color: #007bff;
        background-color: white;
    }

    .curTab:hover {
        color: #007bff;
        background-color: white;
    }

</style>