<template>
    <div class="container-fluid">
        <table class="table table-striped" v-for="activity in structure.activities">
          <thead class="thead-dark">
            <tr>
              <th colspan="3">{{activity.name}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='item in activity.items'>
              <th><input type="checkbox" v-model="item.active"></th>
              <th scope="row" style="min-width: 900px"><input type="text" class="form-control" v-model="item.name"></th>
              <th><input type="number" class="form-control" v-model="item.weight" placeholder="Вес"></th>
              <th></th>
            </tr>
            <tr>
              <th colspan='3'>
                  <input type="text" class="form-control" placeholder="Вид деятельности" v-model="activity.newItem">
              </th>
              <th>
                  <button class="btn btn-info" @click="activity.items.push({name: activity.newItem}); activity.newItem=''">Добавить</button>
              </th>

            </tr>
          </tbody>
        </table>
        <div class="row">
            <div class='col-md-12 text-center'>
                <br>
                <input type="text" class="form-control" v-model="newItem" placeholder="Название группы">
                <br>
                <button class="btn btn-primary" @click="structure.activities.push({name: newItem, items: []})">
                    Добавить группу
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['structure'],
        name: "Activity",
        data () {
            return {
                newItem: ''
            }
        }
    }
</script>

<style scoped>

</style>