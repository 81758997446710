<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <hr>
            <h4>Таблица рейтинга</h4>
            <hr>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-6">

            <table class="table table-bordered text-center" style="margin-top: 30px">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">#</th>
                  <th scope="col" colspan="2">Диапазон баллов</th>
                  <th scope="col" rowspan="2">Финансовое положение</th>
                </tr>
                <tr>
                    <th>От</th>
                    <th>До</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in structure.ratings">
                    <th>{{index + 1}}</th>
                    <th><input type="number" class="form-control" v-model="item.start" disabled> </th>
                    <th><input type="number" class="form-control" v-model="item.end" disabled> </th>
                    <th><input type="text" class="form-control" v-model="item.value" disabled> </th>
                </tr>
              </tbody>
            </table>

        </div>

    </div>
</template>

<script>
    export default {
        name: "RaitingTable",
        props: ['structure']
    }
</script>

<style scoped>

</style>

