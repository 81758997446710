<template>
    <div>
        <v-select v-if='!curActivityItem' :options="allActivitiesItems" v-model='curActivityItem' label='name' placeholder="Выберете вид деятельности"></v-select>
        <div v-if="curActivityItem">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="row">
                        <div class="col-md-2">
                            <button class="btn btn-primary" @click="openIndicators">Фин.показатели</button>
                        </div>
                        <div class="col-md-2 text-center">
                            <button class="btn btn-primary" @click="openBalance">Баланс и ОФР</button>
                        </div>
                        <div class="col-md-4 text-center"><h4>Прочие финансовые аспекты</h4></div>
                        <div class="col-md-2">
                            <button class="btn btn-primary" @click="openTrend" v-if="trend">Тенденция</button>
                        </div>
                        <div class="col-md-2">
                             <button class="btn btn-success" @click="openFinanceScore">Оценка фин. состояния</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 border" v-for="item in blocks" v-if="item.aspects.length > 0">
                    <h5>{{item.name}}</h5>
                    <hr>
                    <div v-for="aspect in item.aspects">
                        <label>{{aspect.name}}</label>
                        <div v-for="elem in aspect.items">
                            <label class="badge-info badge" :title="elem.name" style="max-width: 100%">{{elem.name}}</label>
                            <select class="form-control" v-model="elem.curValue">
                                <option v-for="obj in elem.values" :value="obj" :title="obj.value">{{obj.score}} - {{obj.value}}</option>
                            </select>
                            <br>
                        </div>
                        <br>
                    </div>
                </div>
                <div class="col-md-6 border" v-if="downfactors.length > 0">
                    <h5>Понижающие факторы</h5>
                    <hr>
                    <div v-for="item in downfactors">
                        <label>{{item.name}}</label>
                        <select class="form-control" v-model="item.curValue">
                            <option :value="true">Да</option>
                            <option :value="false">Нет</option>
                        </select>
                        <br>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <br>
                    <br>
                    <button class="btn btn-success" @click="saveAspects">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Aspects",
        props: ['data'],
        async created() {
            await this.getAspects()
        },
        data () {
            return {
                blocks: [],
                indicators: [],
                activities: [],
                allActivitiesItems: [],
                curActivity: false,
                curActivityItem: false,
                downfactors: [],
                ratings: [],
                trend: false,
                financePosition: {
                    bad: 'Плохое',
                    normal: 'Среднее',
                    good: 'Хорошее',
                },
                financeSigns: {
                    bad: 'error',
                    normal: 'warning',
                    good: 'success',
                }
            }
        },
        methods: {
            async getAspects () {
                LoadStart()
                axios.get('/api/map', {params: {id: this.data.methodolog}}).then(response => {
                    this.blocks = response.data.aspect_blocks
                    this.downfactors = response.data.downfactors
                    this.ratings = response.data.ratings
                    this.activities = response.data.activities
                    this.trend = response.data.trend
                    // Собираем все виды деятельности для общего списка
                    this.activities.map(item => {
                        item.items.map(elem => {
                            this.allActivitiesItems.push(elem)
                        })
                    })
                    // Ищем сохраненные значения аспектов
                    this.blocks.map(item => {
                        item.aspects.map(elem => {
                            elem.items.map(choice => {
                                LoadStart()
                                axios.post('/api/count/aspect/value', {id: choice.id, date: this.data.curDate, client: this.data.balanceofr.inn}).then(response => {
                                    choice.values.map(aspectItem => {
                                        if (aspectItem.value === response.data.value) {
                                            choice.curValue = aspectItem
                                        }
                                    })
                                    this.$forceUpdate()
                                }).catch(() => {
                                    swal.fire('Технические ошибки', '', 'error')
                                }).finally(() => {
                                    LoadStop()
                                })
                            })
                        })
                    })
                    this.downfactors.map(item => {
                        LoadStart()
                        axios.post('/api/count/downfactors/value', {id: item.id, date: this.data.curDate, client: this.data.balanceofr.inn}).then(response => {
                            item.curValue = response.data.value
                            this.$forceUpdate()
                        }).catch(() => {
                            swal.fire('Технические ошибки', '', 'error')
                        }).finally(() => {
                            LoadStop()
                        })
                    })
                    // Ищем Сохраненные значения понижающих факторов
                    LoadStop()
                }).catch(() => {
                    LoadStop()
                    swal.fire('Технические ошибки', '', 'error')
                })
            },
            getIndicators () {
                // Выбираем подходящую группу
                LoadStart()
                axios.post('/api/count/indicators/values', {date: this.data.curDate, inn: this.data.balanceofr.inn, map_inn: this.data.methodolog, activity: this.curActivity.id}).then(response => {
                    this.indicators = response.data
                }).catch(() => {
                    swal.fire('Технические ошибки', 'Показатели не посчитались')
                }).finally(() => {
                    LoadStop()
                })
            },
            openBalance () {
                Modal.open({component: 'balance_ofr_modal', data:this.data, width: '1400px'})
            },
            openIndicators () {
                Modal.open({component: 'indicator_count_modal', data: this.indicators, width: '1400px'})
            },
            openTrend () {
                Modal.open({component: 'trend_count_modal', data: this.indicators, width: '1400px'})
            },
            saveAspects () {
                let aspects = []
                let downfactors = []
                let flag = false
                // Добавлеем аспекты
                this.blocks.map(item => {
                    item.aspects.map(elem => {
                        elem.items.map(choice => {
                            if (!choice.curValue) {
                                flag = true
                            } else {
                                aspects.push({aspect: choice.id, value: choice.curValue.value})
                            }
                        })
                    })
                })
                // Добавляем понижающие факторы
                this.downfactors.map(item => {
                    downfactors.push({downfactor: item.id, value: item.curValue})
                })
                if (flag) {
                    return swal.fire('Не выбраны все аспекты', '', 'warning')
                }
                LoadStart()
                axios.post('/api/count/aspect-downfactors/value/save', {date: this.data.curDate, inn: this.data.balanceofr.inn, aspects, downfactors}).then(() => {
                    swal.fire('Аспекты сохранены', '', 'success')
                }).catch(() => {
                    swal.fire('Технические ошибки', '', 'error')
                }).finally(() => {
                    LoadStop()
                })
            },
            openFinanceScore () {
                // Считаем общее кол-во баллов и рейтинг
                let answer = this.getFinanceScore()
                let score = answer.score
                // Счетаем тенденцию, если подключена
                if (this.trend) {
                    score += this.indicators.data[4].trend_total
                }
                // Баллы умножаем на отраслевой коэф риска
                score = parseFloat(score) + this.getIndicatorScore()
                score = (score * this.curActivityItem.weight).toFixed(3)
                // Понижаем положение с учетом понижающих факторов
                let position = this.getPositionByDownfactor(answer.position)
                // Проверяем положение по таблице рейтинга
                position = this.getPositionByRating(score, position)
                swal.fire('Финансовое положение: ' + (this.financePosition[position] ? this.financePosition[position] : 'Не определено'), 'Баллы: ' + (isNaN(score) ? '-' : score), this.financeSigns[position])
            },
            getPositionByDownfactor (position) {
                this.downfactors.map(item => {
                    if (item.curValue) {
                        if (item.type==='bad') {
                            position = 'bad'
                        } else if (item.type === 'normal' && position === 'good') {
                            position = 'normal'
                        }
                    }
                })
                return position
            },
            getPositionByRating(score, position) {
                let pos = ''
                this.ratings.map(item => {
                    if (score >= item.start && score < item.end) {
                        if (position === 'good' && item.value === 'Среднее') {
                            pos = 'normal'
                        } else if (position === 'good' && item.value === 'Плохое') {
                            pos = 'bad'
                        } else if (position === 'normal' && item.value === 'Плохое') {
                            pos = 'bad'
                        } else {
                            pos = position
                        }
                    }
                })
                return pos
            },
            getFinanceScore() {
                let score = 0
                let flag = false
                let position = 'good'
                this.blocks.map(item => {
                    item.aspects.map(elem => {
                        elem.items.map(choice => {
                            if (!String(choice.curValue)) {
                                flag = true
                            } else {
                                score += parseFloat(choice.curValue.score * parseFloat(item.weight) * parseFloat(elem.weight) * parseFloat(choice.weight))
                                if (!choice.curValue.downfactors.good && choice.curValue.downfactors.normal && choice.curValue.downfactors.bad && position === 'good') {
                                    position = 'normal'
                                } else if (!choice.curValue.downfactors.good && !choice.curValue.downfactors.normal && choice.curValue.downfactors.bad && (position === 'normal' || position === 'good')){
                                    position = 'bad'
                                }
                            }
                        })
                    })
                })
                if(flag) {
                    return swal('Выбраны не все аспекты', '', 'warning')
                }
                return {
                    score: score,
                    position: position
                }
            },
            getIndicatorScore () {
                let score = 0
                this.indicators.data[4].data.map(item => {
                    item.indicators.map(elem => {
                        score += parseFloat(elem.value.score)
                    })
                })
                return score
            },
        },
        watch: {
            curActivityItem: function(val) {
                // Находим группу видов деятельности
                this.activities.map(item => {
                    if (item.id === val.activity) {
                        this.curActivity = item
                    }
                })
                // Считаем все показатели с учетом группы
                this.getIndicators()
            }
        }
    }
</script>

<style scoped>

    option {
        width: 30px
    }

    .border {
        padding: 20px;
        border: solid 1px;
        margin-top: 30px
    }

</style>