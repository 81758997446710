<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 margindiv border" v-for="item in structure.sources">
                <input type="checkbox" v-model="item.active">
                {{item.name}}
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: "Reporting",
        props: ['structure'],
        methods: {
            getSources (arr = {}, cur_arr) {
                cur_arr.map(item => {
                    if (item.active) {
                        item.sources.map(elem => {
                            arr[elem.class] = true
                        })
                    }
                })
                return arr
            }
        },
    }
</script>

<style scoped>

    .margindiv {
        margin-top: 3px
    }

</style>