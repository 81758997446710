<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <h4>Расчет финансовых показателей</h4>
                <hr>
                <br>
            </div>
            <div class="col-md-2">
                <button class="btn-lg btn-danger" @click="close">Закрыть</button>
            </div>
        </div>
      <div class="row">
          <div class="col-md-5 border text-center">Наименование строки</div>
          <div class="col-md-1 border text-center" v-for="item in data.data">{{item.date}}</div>
          <div class="col-md-2 border text-center">Прирост по сравнению с предыдущим периодом</div>
      </div>
      <div class="row" v-for="(item, index) in data.data[0].data">
          <div class="col-md-12 text-center border"><b>{{item.name}}</b></div>
          <div class="col-md-12">
              <div class='row' v-for="(elem, index2) in data.data[0].data[index].indicators">
<!--                  Значения-->
                  <div class="col-md-5 border text-center"><b>{{elem.name}}</b></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[0].data[index].indicators[index2].value.value"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[1].data[index].indicators[index2].value.value"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[2].data[index].indicators[index2].value.value"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[3].data[index].indicators[index2].value.value"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[4].data[index].indicators[index2].value.value"></div>
                  <div class="col-md-2 border text-center">{{round((data.data[4].data[index].indicators[index2].value.value / data.data[3].data[index].indicators[index2].value.value * 100 - 100) || 0 )}} %</div>
                  <div class="col-md-5 border text-center">балл</div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[0].data[index].indicators[index2].value.score"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[1].data[index].indicators[index2].value.score"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[2].data[index].indicators[index2].value.score"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[3].data[index].indicators[index2].value.score"></div>
                  <div class="col-md-1 border"><input type="number" step="any" class="form-control" v-model="data.data[4].data[index].indicators[index2].value.score"></div>
              </div>

          </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "IndicatorsCountModal",
        props: ['data'],
        data () {
            return {
                sum: {}
            }
        },

        methods: {
            close () {
                Modal.close()
            },
            round (val) {
               return parseFloat(val).toFixed(2)
            }
        }
    }
</script>

<style scoped>

    .border {
        padding: 2px;
        border: 1px solid;
    }


</style>